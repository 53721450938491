import { Component } from '@angular/core';

@Component({
  template: `
    <app-header />

    <mat-card style="margin: 0 var(--global-padding)">
      <mat-card-header>
        <mat-card-title>Users</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-users-table tableAlias="users" />
      </mat-card-content>
    </mat-card>
  `,
})
export class UserListScreenComponent {}
