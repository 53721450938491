import { Component, Input } from '@angular/core';
import {
  FiltersChannel,
  IFilters,
  IItemsTableOptions,
  ITableDataSource,
  SessionService,
} from '@suvo-bi-lib';
import { TableComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/components/table/table.component';
import { ITablePagination } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-pagination.interface';
import { ITableSort } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { ICprUser } from '../../interface/user.interface';
import { UsersService } from '../../service/users.service';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
})
export class UsersTableComponent extends TableComponent<ICprUser, string> {
  readonly headings = ['displayName', 'email', 'legacyAnalystId', 'lastRefreshTime', 'createdAt'];
  readonly widths = [25, 25, 40, 10];

  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string;

  pageSize = this.options?.defaultPageSize || 15;
  canSort = false;

  constructor(
    sessionService: SessionService,
    private readonly users: UsersService,
  ) {
    super(sessionService);
  }

  protected getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ): Promise<ITableDataSource<ICprUser>> {
    return this.users.getPaginated('', {
      filters,
      sort,
      pagination,
      search,
    }) as unknown as Promise<ITableDataSource<ICprUser>>;
  }
}
