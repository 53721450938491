import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '@suvo-bi-core';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from '../../service/users.service';
import { EditComponent } from 'apps/mba-cpr-survey-portal/src/app/shared/components/edit/edit.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ICprUser } from '../../interface/user.interface';

@Component({
  selector: 'app-user-profile-detail-screen',
  templateUrl: './user-profile-screen.component.html',
})
export class UserProfileScreenComponent implements OnInit, OnDestroy {
  @ViewChild('editComponent') editComponent: EditComponent;
  private readonly unsubscribe$ = new Subject<void>();

  user: ICprUser;
  displayName: string;
  uid: string;

  serverErrors;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly auth: AngularFireAuth,
    private readonly breadcrumb: BreadcrumbsService,
    private readonly usersService: UsersService,
  ) {}

  readonly formGroup = new FormGroup({
    legacyAnalystId: new FormControl<number>(null),
  });

  ngOnInit(): void {
    this.displayName = this.uid = undefined;
    this.breadcrumb.setDynamicNameSwap('username', undefined);
    combineLatest([this.activatedRoute.params, this.auth.user])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async ([{ uid }]) => {
        this.uid = uid;

        const user = await this.usersService.getOne('', uid);
        this.user = user;

        this.displayName = user.displayName;

        this.breadcrumb.setDynamicNameSwap('username', this.displayName);

        this.formGroup.setValue({ legacyAnalystId: user.legacyAnalystId });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async onSave($event: ICprUser) {
    this.serverErrors = null;

    try {
      await this.usersService.patchOne('', this.user._id, $event);
      this.editComponent.markAsUpdated();
    } catch (e) {
      this.serverErrors = [e.error];
      this.editComponent.enable();
    }
  }
}
