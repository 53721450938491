<app-header />

<mat-card style="margin: 0 var(--global-padding)">
  <mat-card-header>
    <mat-card-title>Identity</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>Name: {{ displayName }}</p>
  </mat-card-content>
</mat-card>

@if (user) {
  <mat-card style="margin: var(--global-padding)">
    <mat-card-header>
      <mat-card-title>User Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-edit
        [formGroup]="formGroup"
        permission="user.edit"
        (save)="onSave($event)"
        [errors]="serverErrors"
        #editComponent
      >
        <div class="input-group">
          <mat-form-field>
            <mat-label>Analyst ID</mat-label>
            <input matInput formControlName="legacyAnalystId" type="number" />
          </mat-form-field>
        </div>
      </app-edit>
    </mat-card-content>
  </mat-card>
}

<mat-card style="margin: var(--global-padding)">
  <mat-card-header>
    <mat-card-title>Permissions</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-permissions [uid]="uid"></app-permissions>
  </mat-card-content>
</mat-card>
