<table
  mat-table
  [dataSource]="dataSource$"
  matSort
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="listDropped($event)"
>
  <!-- TODO
    To combat the sorting issues, we can push up to date data into our database
    from firebase during onSignIn - that way we can query it with findAndPaginate
    as normal. Currently it is populated after the fact, so cannot be sorted.
  -->

  <!-- From Firebase - sort won't work -->
  <ng-container matColumnDef="displayName">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let item">
      <span>{{ item.displayName ?? item.name }}</span>
    </td>
  </ng-container>

  <!-- From Firebase - sort won't work -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let item">{{ item.email }}</td>
  </ng-container>

  <ng-container matColumnDef="legacyAnalystId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Analyst ID</th>
    <td mat-cell *matCellDef="let item">{{ item.legacyAnalystId }}</td>
  </ng-container>

  <!-- From Firebase - sort won't work -->
  <ng-container matColumnDef="lastRefreshTime">
    <th mat-header-cell *matHeaderCellDef>Last Activity</th>
    <td mat-cell *matCellDef="let item">{{ item.lastRefreshTime | date: 'medium' : 'UTC' }}</td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Created At</th>
    <td mat-cell *matCellDef="let item">{{ item.createdAt | date: 'medium' : 'UTC' }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headings"></tr>
  <tr
    mat-row
    class="row clickable"
    [class.hidden]="isLoading"
    [routerLink]="row.firebaseUserId"
    *matRowDef="let row; columns: headings"
  ></tr>

  <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
    <td class="mat-cell" [attr.colspan]="headings.length">
      <span>No matching data.</span>
    </td>
  </tr>
</table>

<ng-container *ngIf="isLoading">
  <suvo-bi-skeleton-table-rows
    [rowCount]="pageSize"
    [columnWidths]="widths"
  ></suvo-bi-skeleton-table-rows>
</ng-container>

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
></mat-paginator>
