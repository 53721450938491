import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ITableDataSource } from '@suvo-bi-lib';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { TableApiService } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/services/table-api.service';
import { ITableOptions } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-options.interface';
import { IOverviewPreferences } from '../../overview/interfaces/overview.interfaces';
import { TPermissionFeature, TPermissionScope } from '../../permissions/permission-set.interface';
import { ICprUser } from '../interface/user.interface';
import { IUserSettings } from '../interface/user-settings.interface';

@Injectable()
export class UsersService extends TableApiService<ICprUser> {
  readonly analysts: Promise<ICprUser[]>;
  readonly users: Promise<ICprUser[]>;

  constructor(
    http: HttpClient,
    private readonly authService: AngularFireAuth,
  ) {
    super(http, `${environment.cprServiceApi}user/`);
    this.analysts = this.getAllWithPermission('sample', 'analyse');
    this.users = this.getPaginated('', {}).then(({ data }) => data);
  }

  getAllWithPermission<T extends TPermissionFeature>(feature: T, scope: TPermissionScope<T>) {
    return lastValueFrom(
      this.httpClient.get<ICprUser[]>(
        `${environment.cprServiceApi}user/with-permission/${feature}/${scope}`,
      ),
    );
  }

  /** Gets a user by their Firebase user ID. */
  async getByFirebaseId(firebaseUID: string) {
    const users = await this.users;
    return users.find(({ firebaseUserId }) => firebaseUserId === firebaseUID);
  }

  /** Gets a user by their Mongo `_id`. */
  async getByUserId(uid: string) {
    const users = await this.users;
    return users.find(({ _id }) => _id === uid);
  }

  async getPaginated(
    suffix: string | string[],
    options: ITableOptions,
  ): Promise<ITableDataSource<ICprUser>> {
    const result = await super.getPaginated(suffix, options);
    result.data.forEach((u) => {
      if (u.lastRefreshTime) {
        u.lastRefreshTime = new Date(Date.parse(u.lastRefreshTime as string));
      }
    });
    return result;
  }

  async getMe(): Promise<ICprUser> {
    const firebaseUser = await this.authService.currentUser;
    const token = await firebaseUser.getIdTokenResult();
    return token.claims.context.user;
  }

  getUserSettings() {
    return lastValueFrom(
      this.httpClient.get<IUserSettings>(`${environment.cprServiceApi}user/settings`),
    );
  }

  patchUserSettingsOverviewPreferences(overviewPreferences: IOverviewPreferences) {
    return lastValueFrom(
      this.httpClient.patch<IUserSettings>(
        `${environment.cprServiceApi}user/settings/overview-preferences`,
        overviewPreferences,
      ),
    );
  }
}
